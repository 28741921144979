.AssignmentPageheader{
    display: flex;
    flex-direction: column;
    gap: 15px;
   
}


.AssignmentFlex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileNameFlexAssignment{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

}



.assignedColumnFlex{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inProgressAssignment{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.inProgressAssignment1{
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.AssistantProfileStyle{
    font-family: Roboto;
    font-weight: 700;
}

.AssistantFontStyle{
    font-family: Roboto;
    font-weight: 600;
}
.AssistantFontStyle1{
    font-family: Roboto;
    font-weight: 400;
}
.AssistantFontStyleButton{
    font-family: Roboto;
    font-weight: 400;
    padding: 0px 20px;
}