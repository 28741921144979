.Heading{
    color: #061303;
    font-family: Nunito;
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}

.sub{
    padding-top: 20px;
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.SubHeading{
    color:#59626A;
}

.RedHeading{
    color:#EA4335;
}

.red {
    border: 12px solid #EA4335;
    width:'100%';
  }
  
.content {
    padding-left:50px;
    padding-right:50px;
    padding-top:20px;
    padding-bottom:20px;
  }

.OrderBox {
    border: 1px solid #69A85C;
    background: #FFFFFF;
    border-radius: 5px;
    /* padding: 10px; */
    /* height:324px; */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
   
}


.orderSection{
    text-align: left;
}

.order{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #061303;
    opacity: 0.3;
}

.OrderNumber{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #061303;
}

/* .PriceSection{
    float: right;
} */

.price{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #061303;
    opacity: 0.3;
}

.PriceValue{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #061303;
}

.AddressSection {
    text-align: left;
    /* float: left; */
}

.Firstname {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #061303;
}

.Address {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: rgba(6, 19, 3, 0.7);
}

.Delivery {
    text-align: left;
}

.Date {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #69A85C;
}

.Time {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding-top: 10px;
    color: #EA4335;
}

.products {
    padding-top: 20px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #061303;
    opacity: 0.5;
}

.reject {
    width: 130px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #EA4335;
    border-radius: 26.5px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: rgba(6, 19, 3, 0.7);
}

.accept {
    width: 135px;
    height: 45px;
    background: #89C74A;
    border: 1px solid #89C74A;
    border-radius: 26.5px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.footer1{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #EA4335;
}

.footer2{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color:#59626A;
}
