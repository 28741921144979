.add-txt-orderHistory{
    display: flex;
    margin-top: 20px;
    height: 50px;
    gap: 60px;
    
    /* background-color: white; */
}
.filter-orderHistory{
    display: flex;
   /* margin-top: 10px; */
   margin-left: 65px;
}
.filter-text-OrderHistory{
    margin-top: 15px;
    margin-left: 15px;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    color: #8B8989;
    line-height: 8.75px;
}
.OutColumnFlex{
    display: flex;
    flex-direction: column;
    gap: 5px;
   
}

.inProgressOut{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* margin-top: 10px; */
}
.inProgressOut2{
    display: flex;
    justify-content: flex-end;
    align-items: center;  
    width: 100%;
    height: 100%;
    /* margin-top: 5px; */
    /* margin-right: 50px; */
}

.inProgressHistory1{
    display: flex;
    justify-content: space-around;
    margin-left: 70px;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 0px;
}
.dateFontStyle1{
    font-family: Roboto;
    font-weight: 400;
    
}
.custom-select_1 {
    border: 1px solid rgba(133, 190, 73, 1);
   
    
}
.custom-select:hover,
  .custom-select:focus {
    border-color: rgba(133, 190, 73, 1);
}
   
.custom-select_1 .MuiSelect-icon {
    color: rgba(133, 190, 73, 1);
   
}

.orderIdFontStyle{
    font-family: Roboto;
    font-weight: 600;
}
.dateFontStyle{
    font-family: Roboto;
    font-weight: 400;
}