.light {
  --mainColor: rgba(127, 184, 0, 1);
  --hoverColor: rgba(29, 77, 79, 1);
  --backgroundColor: rgba(247, 250, 255, 255);
  --darkOne: rgba(29, 77, 79, 1);
  --darkTwo: #45424b;
  --lightOne: #919191;
  --lightTwo: #aaa;
}

.left {
  margin-top: 25%;
  margin-bottom: 10%;
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.nearshopzlogo {
  width: 243.13px;
  height: 60px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.img {
  width: 100%;
}

a {
  text-decoration: none;
}
.big-wrapper1 {
  position: relative;
  padding-left: 80px;
  padding-right: 80px;
  /* padding-top: 60px; */
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}
.new_backround {
  background: linear-gradient(135deg, #f7fdf5, #e0f7df);
}
.new_one {
  background: radial-gradient(circle at top left, #ffffff, #e8f8e3 60%);
}

.containerdiv {
  width: 100%;
  margin: 0 auto;
}

header {
  position: relative;
  z-index: 70;
}

header .containerdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  display: none;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 40px;
}

.logo img {
  width: 243.14px;
  margin-right: 0.6rem;
  margin-top: -0.6rem;
}
.changing-text {
  color: green;
  font-weight: 700;
  font-family: Roboto;
  font-size: 48px;
  opacity: 1;
  animation: animate 12s infinite;
}
.logo h3 {
  color: var(--darkTwo);
  font-size: 1.55rem;
  line-height: 1.2;
  font-weight: 700;
}

.links ul {
  display: flex;
  list-style: none;
  align-items: center;
}

.links a {
  color: var(--lightTwo);
  margin-left: 4.5rem;
  display: inline-block;
  transition: 0.3s;
}

.links a:hover {
  color: var(--hoverColor);
  transform: scale(1.05);
}

.btn {
  display: inline-block;
  padding: 0.9rem 1.9rem;
  color: #fff !important;
  border-radius: 16px;
  text-transform: capitalize;
  transition: 0.3s;
}

.btn:hover {
  transform: scale(1) !important;
}

.hamburger-menu {
  position: relative;
  z-index: 99;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}

.hamburger-menu .bar {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: var(--darkTwo);
  border-radius: 3px;
  transition: 0.5s;
}

.bar::before,
.bar::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkTwo);
  border-radius: 3px;
  transition: 0.5s;
}

.bar::before {
  transform: translateY(-8px);
}

.bar::after {
  transform: translateY(8px);
}

.big-wrapper.active .hamburger-menu .bar {
  background-color: transparent;
}

.big-wrapper.active .bar::before {
  transform: translateY(0) rotate(-45deg);
}

.big-wrapper.active .bar::after {
  transform: translateY(0) rotate(45deg);
}

.showcase-area .containerdiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.big-title {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.big-title h1 {
  font-size: 58px;
  font-weight: 700;
  color: rgba(49, 49, 49, 1);
  text-transform: capitalize;
  /* line-height: 1.4; */
}

.changing-text::before {
  content: "Home Business";
  color: green;
  font-weight: 700;
  font-family: Roboto;
  font-size: 48px;
  opacity: 1;
  animation: changeText 12s infinite;
}

@keyframes changeText {
  0% {
    content: "Supermarket";
  }
  16% {
    content: "Restaurants";
  }
  32% {
    content: "Bakery";
  }
  48% {
    content: "Grocery";
  }
  64% {
    content: "Fish & Meat";
  }
  80% {
    content: "Home Business";
  }
}

.shopsnearby::before {
  content: "Supermarket";
  animation: animate infinite 12s;
  /* font-size: 77px; */
  color: rgba(0, 173, 69, 1);
}
.big-title p {
  font-size: 52px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0px;
}

.oneatatime {
  color: var(--mainColor);
}

.locationtextfield {
  background: #ffffff;
  border: 1px solid #e2f6b9;
  border-radius: 30px;
  height: 56px;
  width: 100%;
  display: flex;
  color: var(--mainColor);
  display: flex;
  align-items: center;
}

.locationicon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10%;
  color: var(--mainColor);
}

.textfield {
  /* padding-left: 10px; */
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.divider {
  opacity: 0.3;
  border: 1px solid #000000;
  height: 50%;
}

.seller {
  background: linear-gradient(to bottom, #f5fff5, #e0ffe0);
  height: 100vh;
  margin: 0;
}

.text {
  color: #ffffff;
  font-size: 20px;
  max-width: 600px;
  font-weight: 500;
  padding-left: 10px;
}

.showcase-area .btn {
  box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.05);
}

.person {
  width: 123%;
  transform: translate(15%, 25px);
}

.toggle-btn {
  display: inline-block;
  border: none;
  background: var(--darkTwo);
  color: var(--backgroundColor);
  outline: none;
  cursor: pointer;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  font-size: 1.1rem;
  transition: 0.3s;
}

.toggle-btn i {
  line-height: 39px;
}

.toggle-btn:hover {
  background: var(--mainColor);
}

.big-wrapper.light .toggle-btn i:last-child {
  display: none;
}

.big-wrapper.light .toggle-btn i:first-child {
  display: block;
}

.big-wrapper.dark .toggle-btn i:last-child {
  display: block;
}

.big-wrapper.dark .toggle-btn i:first-child {
  display: none;
}

.shape {
  position: absolute;
  z-index: 0;
  width: 210px;
  opacity: 1;
}

.copy {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  animation: appear 1s 1 both;
}

@keyframes appear {
  0% {
    clip-path: circle(30% at -25% -25%);
  }

  100% {
    clip-path: circle(150% at 0 0);
  }
}

@media screen and (max-width: 870px) {
  .hamburger-menu {
    display: flex;
  }

  .links {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 450px;
    width: 100%;
    height: 100%;
    background-color: var(--mainColor);
    z-index: 95;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: 0.5s;
  }

  .links ul {
    flex-direction: column;
  }

  .links a {
    color: #fff;
    margin-left: 0;
    padding: 2rem 0;
  }

  .links .btn {
    background: none;
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }

  .big-wrapper.active .links {
    transform: translateX(0);
    box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.4);
  }

  .big-wrapper.active .overlay {
    pointer-events: all;
    opacity: 1;
  }

  .showcase-area {
    margin: 0 auto;
  }

  .showcase-area .containerdiv {
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: 2rem;
  }

  .big-title {
    font-size: 64px;
    font-weight: 700;
    white-space: break-spaces;
  }

  .text {
    font-size: 0.95rem;
    margin: 1.4rem 0 1.5rem;
  }

  .person {
    width: 100%;
    transform: none;
  }

  .logo h3 {
    font-size: 1.25rem;
  }

  .shape {
    position: absolute;
    width: 210px;
  }
}

.seller-app-screens {
  width: 322px;
  height: 363px;
  max-width: 100%;
  max-height: 100%;
}

@keyframes animate {
  0% {
    content: "Supermarket";
  }
  16% {
    content: "Restaurants";
  }
  32% {
    content: "Bakery";
  }
  48% {
    content: "Grocery";
  }
  64% {
    content: "Fish & Meat";
  }
  80% {
    content: "Home Business";
  }
}
.big-wrapper {
  position: relative;
  padding: 60px 80px;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-size: cover;
  animation: changeBackground 12s infinite ease-in-out;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.big-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../utils/images/Variant11.webp"),
    url("../../utils/images/Variant22.webp"),
    url("../../utils/images/Variant33.webp"),
    url("../../utils/images/Variant44.webp"),
    url("../../utils/images/Variant55.webp"),
    url("../../utils/images/Variant66.webp");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

@keyframes changeBackground {
  0%,
  100% {
    background-image: url("../../utils/images/Variant11.webp");
    opacity: 1;
  }
  16% {
    background-image: url("../../utils/images/Variant22.webp");
    opacity: 1;
  }
  32% {
    background-image: url("../../utils/images/Variant33.webp");
    opacity: 1;
  }
  48% {
    background-image: url("../../utils/images/Variant44.webp");
    opacity: 1;
  }
  64% {
    background-image: url("../../utils/images/Variant55.webp");
    opacity: 1;
  }
  80% {
    background-image: url("../../utils/images/Variant66.webp");
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
