

.NotificationFontStyle{
    font-family: Roboto;
    font-weight: 500;
}

.NotificationFontStyle400{
    font-family: Roboto;
    font-weight: 400;
    color:#7D7878;
}