
.columnSlotflex{
    display: flex;
    flex-direction: column;
}

.buttonSlotflexheader{
    display: flex;
    gap:10px;
    justify-content: space-between;
}

.deliverySlotStyle{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.SliderHeaderflex{
    display: flex;
    gap:20px;
    padding: 20px 10px;
   
}


.insiderCardflexColumn{
    display: flex;
    flex-direction: column;
    gap:30px;
    width:300px;
}

.scrollContainer {
    overflow-x: auto;
    white-space: nowrap; 
   
  } 
   
  /* .scrollContainer::-webkit-scrollbar {
    display: none;
  } */

  .footerkmflex{
    display: flex;
    justify-content: space-between;
    gap:60px;
  }

  .subfooterflex{
    display: flex;
    gap:20px;
    align-items: center;
   
  }

  .flexcontainermanage{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
  }

/* .subfooterflexnew{
    display: flex;
    gap:5px;
} */

.DeliSettingsContainer{
    display: flex;
    justify-content: space-between;
}

.flexboxforcheckbox{
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.DeliSettingsColFlex{
    display: flex;
    flex-direction: column;
    gap:15px;
}

/* .deliContainer{
    display: flex;
    flex-direction: column;
    gap:25px;
} */

.inputfeildsplit{
    display: flex;
    gap:20px;
}

.newGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Equivalent to 50% each */
    column-gap: 30px;
}


.inputSplitflex{
    display: flex;
    gap: 15px;
}

.shopTimingflex{
    display: flex;
    justify-content: flex-start;
    gap:10px;
    margin-right: 24px;
}

.ManageInitialBtnStyle{
    font-family: Roboto;
    font-weight: 500;
}

.chooseHolidaysStyle{
    font-family: Roboto;
    font-weight: 600;
}

.chooseHolidaysStyle1{
    font-family: Roboto;
    font-weight: 400;
}

.weekFontStyle{
    font-family: Roboto;
    font-weight: 500;
}

.DeliveryStyle{
    font-family: Nunito;
    font-weight: 600;
}

.kmStyle{
    font-family: Roboto;
    font-weight: 400;
    
}

.manageSlotButton{
    font-family: Roboto;
    font-weight: 700;
}

.css-1eehwzh-MuiGrid-root{
    min-width:90% !important;
}
.css-mhc70k-MuiGrid-root{
    margin-left: 0px!important;
    margin-top: 0px !important;
}

/* .css-mhc70k-MuiGrid-root>.MuiGrid-item{
    /* padding-top: 0px !important; */
    /* padding-left: 0px !important; 
}

/* .css-1yrymlm-MuiTypography-root {
    font-size: 0px;
} */