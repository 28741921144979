/* .activeorderBox{
    width: 90%;
    height: 60px;
    background-color:#85BE4940;
    border-radius: 15px;
    margin-top: 10px;
    text-align: center;
    line-height: 60px;
    margin-right: 200px;
}
.activeOrderGrid{
    
    display: grid;
    column-gap: 78px;
    margin-top: 30px;
    grid-template-columns: 98px 700px 900px;
    width: 90%;
    

}
.activeOrderGridOne{
    display: grid;
    column-gap: 650px;
    
    grid-template-columns: 300px 800px;
    width: 90%;
}
.OrderReadyButton{
    display: flex;
  justify-content: center;
  align-items: center;
  
} */
.MainActiveOrder{
  display: flex;
  
  font-family: "Roboto";
   font-weight: 400;
   color: #646464;
}
.MainActive{
  font-family: "Roboto";
  font-weight: 400;
  color: #646464;
}
.address{
  margin-left: 8px;
   font-weight: 700;
   font-family: "Roboto";
   padding-left: 0px;
}
.numberStyle{
  margin-right: 20px;
  font-weight: 700;
  font-family: "Roboto";
  margin-left: 10px;
}
.itemDetails{
  font-weight: 400;
  font-family: "Roboto";
}
.productDetail{
  font-weight: 500;
  font-family: "Roboto";
  /* margin-top: 20px; */
  
}
.productDetails1{
  font-weight: 500;
  font-family: "Roboto";
  margin-top: 20px;
  line-height: 100%;
  
  /* margin-right: 5px; */
  
}



.deliveryButton{
  font-family: "Roboto";
  font-weight: 700;
  
}
.nameStyle{
  font-family: "Nunito";
  font-weight: 700;
  padding-left: 0px;
}
.MainActives{
  
    display: flex;
    /* gap: 3px; */
    font-family: "Roboto";
    font-weight: 400;
    
    color: #85BE49;
  
  
}
.activeorderBox{
  width: 100%;
  height: 60px;
  background-color: #85BE4940;
  border-radius: 15px;
  margin-top: 20px;
  display: flex; /* Add flex display */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-family: "Roboto";
}
.textStyleActive{
  font-weight: 550;
  font-family: "Roboto";
  text-align: center;
  
}
.activeOrderGrid{
    
     display: grid;
    column-gap: 78px;
    margin-top: 30px;
    grid-template-columns: 98px 700px 900px;
    width: 90%; 
         

}

.activeOrderGridOne{
    display: grid;
    column-gap: 650px;
    
    grid-template-columns: 300px 800px;
    width: 90%;
}
.OrderReadyButton{
    display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
/* Counter.css */
.counter-container {
    border: 1px solid #E0E0E0;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    margin-left: 90px;
 }
  
.counter-button {
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
}
  
.counter-value {
    font-size: 18px;
}
/* .MuiGrid-root > .MuiGrid-item{
  padding-left: 0px;
}
.MuiGrid-item{
  padding-left: 0px;
} */