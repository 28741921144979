/* .vertical-timeline-element-icon {
    position: absolute;
    top: -32px;
    left: 13px;
    width: 13px;
    height: 12px;
    border-radius: 50%;

} */
.orderHistoryBox{
    
    width: 100%;
  height: 60px;
  background-color: #85BE4940;
  border-radius: 15px;
  margin-top: 20px;
  display: flex; /* Add flex display */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-family: "Roboto";

}
.MuiTimelineContent-root{
    margin: -14px -13px 0px 0px;
    line-height: 1.5;
    font-size: 1rem;
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 6px 16px;  
    text-align: left;
}
.MuiTimeline-root{
    padding: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
/* .vertical-timeline-element {
    margin-left: 0;
    margin-bottom: 0px;
    height: 10px;
}

.timeline-container{
    margin-left: -450px;
    width: 90%;
}
.vertical-timeline{
    padding: 0 !important;
    
}
.vertical-timeline::before{
    height: 88% !important;
}
.vertical-timeline-element-content {
    box-shadow: none !important;
    margin-top: -50px;
    background: none !important;
    font-weight:'bold';
     color: 'black' !important;
     font-size: medium ;
} */
.textStyleHistory{
    font-weight: 600;
    font-family: "Roboto";
    text-align: center;
}
.productDetails{
    font-weight: 500;
    font-family: "Roboto";
    margin-top: 20px;
    
}
.timeline-containerOut {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
  }
  
  .timeline-entryOut {
    display: flex;
    gap: 10px;
  }
  
.timeline-markerOut {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 80px;
  }
  
.dotOut {
    background: #85BE49;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
  
.connecting-lineOut {
    border-left: 3px solid #85BE49;
    height: calc(100% - -19px);
    position: absolute;
    top: 10px;
  }
  
.timeline-contentOut {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    line-height: 1;
    /* gap: 10px; */
    text-align: left;
    width: 100%;
  }  