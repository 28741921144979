.headerstyle{
    color: black;
    text-align: center;
    font-family: Roboto;
    font-weight: 500;
}
.content-card{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #5A7184;
}
.headerstyle-1{
    text-align: center;
    font-family: Roboto;
    color: #13801C;
    font-weight: 500;
}
.content-card-1{
    text-align: justify;
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #5A7184;
}
/* @media only screen and (max-width: 768px) {
    .card {
      width: 90%; 
      margin: 10px auto;
    }
  
    .card-content {
      padding: 10px; 
    }
  } */