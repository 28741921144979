

form {
	display: block;
	/* margin-top: 0em; */
	margin-block-end: 1em;
	font-family: 'Varela Round', sans-serif;
	height: auto;

}

.login-heading {
	color: #324658;
	font-weight: 800;
	font-size: 18px;
	font-family: 'Varela Round', sans-serif;
}

h4 {
	display: block;
	font-size: 2em;
	margin-block-start: 0.67em;
	margin-block-end: 0.67em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: 550;
	font-family: 'Varela Round', sans-serif;
}

h2 {
	display: block;
	font-size: 1.5em;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
	font-family: 'Varela Round', sans-serif;
}

#login-form {
	width: 100%;
	font-family: arial, helvetica, sans-serif;
	box-sizing: border-box;
}

.login-row {
	padding-left: 40px;
	padding-right: 40px;
	font-size: 14px;
	width: 130px;
	text-align: right;
	color: #33475b;
	display: block;
	float: none;
	width: auto;
	font-weight: 500;
	text-align: left;
	line-height: 20px;
	padding-top: 15px;
	margin-bottom: 4px;
}

@media only screen and (max-width: 768px) {
	.login-row {
		font-size: 14px;
		width: 100%;
		text-align: right;
		color: #33475b;
		display: block;
		float: none;
		font-weight: 500;
		text-align: left;
	}
}

.forgot-row {
	padding-left: 40px;
	/* padding-right: 10px; */
	font-size: 14px;
	width: 130px;
	color: #889caf;
	display: block;
	float: none;
	width: auto;
	font-weight: 500;
	line-height: 20px;
	padding-top: 15px;
}

@media only screen and (max-width: 768px) {
	.forgot-row {
		padding-left: 40px;
		font-size: 14px;
		width: 100%;
		color: #889caf;
		display: block;
		float: none;
		/* width: auto; */
		font-weight: 500;
		padding-top: 15px;
	}
}

.login-Col {
	align-self: flex-start;
	flex: 1 1 100%;
	/* padding: 0 10px; */
}

.login_field_input {
	border: 0;
	border-radius: 0;
	background: none;
	border-bottom: 2px solid #cad5e2;
	width: 100%;
	outline: none;
	font-family: 'Varela Round', sans-serif;
}

.login-label {
	font-size: 13px;
	color: #33475b;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 4px;
	font-family: 'Varela Round', sans-serif;
}

.password_btn {
	cursor: pointer;
	font-size: 13px;
	font-weight: 600;
	color: #889caf;
	background: none;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	margin-top: 1px;
	font-family: 'Varela Round', sans-serif;
}

.login_container {
	box-sizing: border-box;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.70);
	box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.10);
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	width: 100%;
}

@media only screen and (max-width: 768px) {

	/* For mobile phones: */
	.login_container {
		width: 100%;
		color: rgb(255, 255, 255);
		font-size: 14px;
		background-color: white;
		box-shadow: none;
		font-family: 'Varela Round', sans-serif;
		padding: 20px;
		margin: 0;
		height: 100%;
	}
}

/* .textfield {
	width: 270px;
} */

@media only screen and (max-width: 768px) {
	.textfield {
		width: 100%;
	}
}

#Cancel-btn {
	justify-content: center;
	color: #75c224;
	background-color: white;
	/* min-width: 100%; */
	width: 100px;
	height: 30px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-image: initial;
	overflow: hidden;
	border: 2px solid #75c224;
	outline: #75c224;
	min-height: 14px;
	font-family: 'Varela Round', sans-serif;
}

#Submit-btn {
	justify-content: center;
	color: white;
	background-color: #75c224;
	/* min-width: 100%; */
	width: 100px;
	height: 30px;
	max-height: 100%;
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-image: initial;
	overflow: hidden;
	outline: none;
	min-height: 14px;
	font-family: 'Varela Round', sans-serif;
}

.signin-heading {
	color: #313131;
	font-family: 'Varela Round', sans-serif;
	font-size: 44px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 0;
	margin: 0;
}

.signin-subbheading {
	color: #313131;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.textfield-label {
    color: rgba(49, 49, 49, 1);
    font-size: 16px;
    font-weight: 600;
}

.form-fields {
    width: 100%;
    height: 55px;
    /* padding: 0px 30px 0px 30px; */
    border-radius: 12px;
    /* border: 1px solid rgba(185, 185, 185, 1); */
}

.signin-btn {
	width: 100%;
	height: 50px;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: rgba(0, 173, 69, 1);
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	border-radius: 12px;
}
