.add-txt{
    display: flex;
    margin-top: 40px;
    height: 50px;
    gap: 60px;
    color: black;
    
}
.Main{
    display: flex;
    gap: 3px;
    font-family: Roboto;
    font-weight: 400;
    color: #646464;
}
.card-pdt{
    margin-top: 30px;
}
.off-cls{
    margin-top: 4px;
    margin-left: 8px;
    color: white;
    font-family: 'Nunito';
    font-size: 14px;
    font-weight:500;
    line-height: 22px;
}
.filter1{
    display: flex;
   margin-top: 10px;
}
.filter-text{
    margin-top: 0px;
    margin-left: 15px;
    font-family: Roboto;
    font-weight: 400;
    color: #8B8989;
}