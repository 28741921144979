/* 
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap') */


.callUsText{
    font-family: Roboto;
    font-weight: 500;
}

.secondStyle{
    font-family: Roboto;
    color: #8B8989;
    font-weight: 400;
    
}

.buttonHelpStyle{
    font-family: Poppins;
    font-weight: 600;
}