.list.active {
    background-color: #ffffff;
    width: 180;
    text-align: center;
    margin: 0 15px;
    border: 1px solid #75c224;
    color:#75c224;
    cursor: pointer;
  }
  .list{
    color:#9b9b9b;
    cursor: pointer;
  }
  .text-row{
    padding-bottom:50px;
}

h5{
    font-weight:600;
    padding-bottom: 30px;
}