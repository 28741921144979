

.columnFlexRegister{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.navigationstyleassitant{
    display: flex;
    gap: 5px;
}

.shopAssistantheaderStyle{
    margin-top: 10px;
    font-weight: 800;
}

.ShopAssistantGrid{
    display: grid;
    grid-template-columns: auto auto;
    gap:20px;
}

.ShopAssistantFlex{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ShopAssistantFontStyle{
    font-family: Roboto;
    font-weight: 600;
}

.ShopUpdateButtonStyle{
    font-family: Roboto;
    font-weight: 700;
  }

.shopNavigationStyle{
    font-family: Roboto;
    font-weight: 400;
}

.ShopCardStyle{
    font-family: Roboto;
    font-weight: 500;
}
.ShopPopupStyle{
    font-family: Nunito;
    font-weight: 500;
}

.updateProfileProfileImage{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

}

.updateProfileProfileImage1{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.profile{
    display: inline;
    position:relative;
  }

  
svg-icon {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #72B01D;
    border-radius: 50%;
    /* padding: 0px 5px 0px 5px; */
    position: absolute;
    top: 65%;
    left: 80%;
  }
