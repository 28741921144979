.wv-center {
    min-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.no-coupons-container {
    height: calc(100vh - 278px);
    flex-direction: column;
}

.no-coupons-container .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    color: #1a181e;
}

.description {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    color: #424242;
}

.m8 {
    margin: 8px;
}

.mb0 {
    margin-bottom: 2;
}

.couponButton {
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(137, 199, 74, 1);
    width: 150px;
    height: 47px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    overflow: hidden;
    outline: none;
    min-height: 14px;

}
.productButton {
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(137, 199, 74, 1);
    width: 100%;
    height: 37px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    overflow: hidden;
    min-height: 14px;
    outline: none;

}
.productButtonsave {
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(137, 199, 74, 1);
    width: 30%;
    height: 37px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    overflow: hidden;
    min-height: 14px;
    outline: none;

}
.section-text-5 {

    font-size: 16px;
/* line-height: 24px; */
    font-weight: 600;
    padding-left: 10px;
    position: relative;
    top: 19px

}



.section-text-sub {

    font-size: 14px;
/* line-height: 24px; */
    font-weight: 400;
    padding-left: 10px;
    position: relative;
    top: 3px;

}



.create-coupon{
    font-family: 'Roboto';
    font-weight: 700;
}


.ListItem {

    display: flex;

    flex-direction: column;

    justify-content: center;

    vertical-align: middle;

}
.Coupon-Container {

    border-radius: 8px;
    box-shadow: 0 2px 6px #1a181e0a;
    background-color: #fff;
}
.CouponType{
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}
.CouponHeading {
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}
.formDiv{
    padding-top:10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.centerDiv{
    padding-top:10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.custm-button{
    width: 98%;
    display: flex;
    gap: 25px;
}
.coupon-card-main{
    /* width: 100%; */
    margin-top: 20px;
    display: flex;
    gap: 25px;
}
.coupon-card
{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 30px;
    box-shadow: 0 2px 6px #1a181e0a;
    /* padding: 25px; */
    z-index: 1;
    /* width: 50%; */
    background-color: black;
}
.card-off{
    display: flex;
   
    align-items: center; 
} 
.card-bottom{
    display: flex;
    
    justify-content: space-between;
    width: 100%;
    background-color: white;
    border-color: none;
    border-radius: 0px 0px 20px 30px;
    border-bottom: 1px solid #E0E0E0;
    
}
.remaining{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito';
    font-weight: 400;
    
    color: black;
    opacity: 50%;
    
}
.coupon-item-container
{
    /* padding: 25px; */
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
}

.coupon-heading
{
    display: flex;
    width: 100%;
}

.coupon-name
{
    width: 100%;
    float: left;
    text-align: left;
}
.toggle{
    /* padding-left: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.switch-button
{
    width: 100%;
    float: right;
    text-align: right;
}

.name
{
    /* padding-left: 10px; */
    font-family: 'Nunito';
    font-weight: 900;
    
    letter-spacing: 4px;
    line-height: 28px;
   
    color: #58B908;
}

.coupon-description
{
    display: flex;
    gap: 10px;
    width: 100%;
   
    text-align: left;
    font-family: 'Nunito';
    
    
    font-weight: 700;
    color: #B6B6B6;
    /* padding-bottom: 16px!important;
    padding-top: 20px ; */
}

/* .coupon-divider
{
    border: 1px dashed gray;
    height: 0;
    width: 100%;
} */
.custm-button{
    width:"100%"
}


/* .Off{
    writing-mode: vertical-lr;
    border: solid black 1px;
    color: #BDBDBD;
    display: inline-block;
   
    font-family: 'Nunito';
    font-weight: 800;
   
    letter-spacing: 2px;
} */
/* .times-used-div
{
    display: flex;
    /* width: 50%; 
    float: left;
    text-align: left;
    flex-direction: column;
} */

.times-used-div-heading
{
    
    font-family: 'Nunito';
    font-weight: 400;
    
    color: #B6B6B6;
}

.times-used-div-desc
{
   display: flex;
   align-items: center;
   justify-content: center;
    font-weight: 500;
    color: #B6B6B6;

}

/* .circle
{
    width: 30px;
    height: 50px;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    background-color: #F4F5F9;
}

.circle1
{
    left: 20px;
    top: 160px;
}

.circle2
{
    right: 150px;
    top: 160px;
} */

/*  
.circle {
  
   
    position:relative;
    
    /* z-index: 2;
    background-color: #F4F5F9; 
}  */



/* .circle2 {
    right: 49%; /* Adjust the right position based on percentage 
    top: 50%; /* Adjust the top position based on percentage 
    transform: translateY(-50%); /* Center the circle vertically 
} */
/* 
@media (max-width: 768px) {
    /* Adjust positions for smaller screens 
    .circle1 {
        left: 3%; /* Adjust left position for smaller screens 
    }
    
    .circle2 {
        right: 3%; /* Adjust right position for smaller screens 
    }
} */


.circle1 {
    position: absolute;
    left: 0;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 40px;
    height: 60px;
    border-radius: 50%;
    /* background: rgb(244, 245, 249); */
    overflow: hidden;

}

.circle2{
    position: absolute;
    right: 0;
    transform: translate(50%, -50%);
    top: 50%;
    width: 40px;
    height: 60px;
    border-radius: 50%;
    /* background: rgb(244, 245, 249);  */
   overflow: hidden;
    
}




.half1{
    display: flex;
    height: 100%;
    width: 100%;
}

.content1{
    height:100%;
    width:100%;
}

.half2{
    display: flex;
    height: 100%;
    width: 100%;
}

.content2{
    height:100%;
    width:100%;
}

/* 

@media (min-width: 900px) and (max-width: 1200px) {
    .circle2{
        left:90%;
    }
    
  }
@media (min-width: 900px) and (max-width: 1000px) {
    .circle2{
        left:87%;
    }
    
  }

@media (min-width: 725px) and (max-width: 900px) {
    .circle2{
        left:90%;
    }
    
  }
  @media (min-width: 600px) and (max-width: 725px) {
    .circle2{
        left:87%;
    }
    
  }
@media (min-width: 380px) and (max-width: 510px) {
    .circle2{
        left:90%;
    }
  }
@media (min-width: 300px) and (max-width: 380px) {
    .circle2{
        left:87%;
    }
  }
  
@media (min-width: 0px) and (max-width: 300px) {
    .circle2{
        left:87%;
    }
  }
   */