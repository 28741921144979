.main{
    display: flex;
    gap: 3px;
    font-family: Roboto;
    font-weight: 400;
    color: #646464;
}
.SubCategory{
    font-family: Roboto;
    font-weight: 600;
    padding-top: 20px ;
    box-sizing: border-box;
}
.Drinks{
    font-family: Roboto;
    font-weight: 500;
}
.product-choose{
    font-family: Roboto;
    font-weight: 400;
    color: #8B8989;
}