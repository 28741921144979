body {
  margin: 0;
  font-family: "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./utils/images/NewBg.png"); */
  background-color: white;
  /*  */
}
#root{
  background-color: white; 
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  
  html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
  }
  
  html body {
    padding: 0;
    margin: 0;
    overflow-x: inherit;
    /* padding:5px 5px 5px 30px; */
    background:#F4F5F9;
  }

  @media (max-width: 900px) {
    html body {
        padding: 0px;
    }
}

  @media (min-width: 600px){
    .css-is854b {
      width: 100% !important ;
  }
  }

  .css-hyum1k-MuiToolbar-root {
    min-height: 70px;
    width:100%;
}

.css-1cn2zgl{
 background: #F4F5F9;
 height: 100%;
}


.css-isbt42 {
  margin-top: 0px !important; 
      width: 100% !important; 
       margin-left: 0px !important;
      
      }

      .css-froe0x {
        padding-top:0px !important;
        }
        .css-118e0h6{
          background:white;
          }

          
.css-160unip {
  padding-top:0px;
  }
  
  .css-4v9257{
    max-width: 50% !important;
  }

  .css-szi2ao-MuiPaper-root-MuiPopover-paper{
    box-shadow: none;
  }

  .css-yeojq6-MuiListItem-root.Mui-selected{
    background-color: transparent;
  }

  .css-pta4sz{
    box-shadow: none;
  }

  .css-1ctwbie.Mui-selected{
      background-color: transparent;
  }