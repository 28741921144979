/* /* .chartCardStyle{
    display: flex; 
    
   
} 
*/
.chartCardStyle1{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
 
}  

.buttonStyleAssignment{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.scrollContainer {
  width: 100%;
    overflow-x: auto;
    white-space: nowrap; /* Prevent wrapping of flex items */
  }
   
  .scrollContainer::-webkit-scrollbar {
    display: none;
  }


  .dashboardFontFamily{
    font-family: Roboto;
    font-weight: 400;
  }

  .dashboardChartFontFamily{
    font-family: Roboto;
    font-weight: 500;
  }