.main{
    font-family: Roboto;
    font-weight: 400;
    color: #646464;
}

.red-start{
    color: red;
}

.flatLabelFont{
    font-family: Roboto;
    font-weight: 400;
}