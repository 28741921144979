.edit-icon-st {
  display: flex;
  justify-content: flex-end;
  gap: 11px;
  padding: 46px;
}
.card-pdt-ctgry {
  margin-top: 20px;
}
.Card-category-text{
  font-family: "Roboto";
  font-weight: 600;
}
.add-icn{
    margin-right: 10px;
    fill: white;
}
.card-category-content {
  box-sizing: border-box;
  padding: 9px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
}
.category-card-1 {
  padding: 6px;
  font-family: "Roboto";

  font-weight: 600;

  letter-spacing: 0px;
  box-sizing: border-box;
  margin-left: 36px;
}
.category-card {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding: 6px;
  margin-left: 36px;
}
.edit-pdt {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.display-rank {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  /* justify-content:space-between; */
}
.text {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  padding: 5px;
  line-height: 22px;
}
.edit-button {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  justify-content: space-evenly;
  padding: 20px;
  box-sizing: border-box;
}
.product-button {
  display: flex;
  gap: 20px;
}
.button-text {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.text{
  color: black;
}
.custom-select_1 {
  border: 1px solid rgba(133, 190, 73, 1);
 color: black;
}
.custom-select_1:hover,
.custom-select_1:focus {
  border-color: rgba(133, 190, 73, 1);
  color:black;
}

.custom-select_1 .MuiSelect-icon {
  border-color: rgba(133, 190, 73, 1); 
}