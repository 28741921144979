/* NX = not expanded */
.side-nav-container {
	background-color: #293332;
	width: 260px;
	height: 100vh;
	position: relative;
	color: #000;
	transition: 0.4s;
	box-shadow: 0 0 0 1px #E8E9EB;
}
.side-nav-container-NX {
	width: 85px;
}
.nav-menu{
	color: white;
}
.nav-upper,
.nav-heading,
.nav-menu,
.menu-item,
.nav-footer {
	/* border: 2px solid white; */
	/* display: grid; */
	align-items: center;
	text-align: left;
}
.nav-logo{
    display: flex;
	padding-left: 40px; 
	gap:24px;
	width: 100%;
    margin-top: 10px;	
}
.shopassistant-logo{
    display: flex;
	padding-left: 40px; 
	/* gap:24px; */
	width: 100%;
    margin-top: 10px;	
}
.nav-logo-text-head{
	/* padding-left: 20px; */
	color: white;
	font-family: roboto;
	font-weight: 400;
}
.nav-logo-text{
	padding-top: 0;
	font-family: Roboto;
	font-weight: 700;
}

.nav-heading {
	/* grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr; */
	height: 75px;
	margin-top: 30px;
}

.nav-brand {
	display: flex;
	color: var(--light);
}
.nav-brand img {
	width: 40px;
	padding: 0 10px;
}

.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	margin: auto;
}
  
  .menuitem.active .sidenav-text {
	color: #85BE49; 
  }
  .menuitem.active .icon{
	fill: #85BE49;
  }
.icon{
	fill: white;
}
.hamburger span {
	display: block;
	margin-top: 5px;
	background-color: var(--light);
	border-radius: 15px;
	height: 5px;
	width: 35px;

	transition: 0.4s;
}

.hamburger:hover span {
	background-color: var(--primary);
}

.hamburger-in:hover span:nth-child(1) {
	width: 25px;
	transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 25px;
	transform: translateY(-4px) rotate(25deg);
}
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* .hamburger-out {
	margin-left: 24px;
} */
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(155deg);
}

/* .nav-menu {
	grid-template-rows: repeat(7, 1fr);
	margin-top: 50px;
} */

.menu-item {
	/* height: 63px; */
	display: flex;
	/* color: var(--light); */
	text-decoration: none;
	text-transform: uppercase;
	margin: auto 20px;
	border-radius: 10px;
	text-align: left;
	justify-content: left;
	align-items: center;
	font-family: roboto;
    font-weight: 400;
    font-size: 14px;
	/* padding-left: 15px; */
}

.menu-item-disabled {
	display:none;
}

.menu-item-NX {
	margin: auto;
}
.menuitem:hover{
   
   background-color: #545D5FBF;
	color: white;
	width:230px;
	border-radius: 100px;
	
}

.menu-item img {
	width: 30px;
	padding: 0 20px;
}

.nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
}

.nav-details {
	display: flex;
}
.nav-details img {
	width: 50px;
	padding: 0 20px;
}

.nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
}

.nav-footer-user-position {
	margin-top: -15px;
	color: var(--gray);
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	background-color: #89c74a;
}
.sub-menu-item:hover{
    background-color: #89c74a;
    color: #fff;
	border-radius:8px;
	width:100%
}
.drawer{
background: transparent;
border: none;
display: flex;
justify-content: center;
text-align: center;
align-items: center;
cursor: pointer;
padding-top:30px;
}
.drawer-x{
margin-left:89%;
position : relative;
top: 19px;
}
	

