.updateProfileflex{
    display: flex;
    gap:25px;
    justify-content: flex-start;
    align-items: center;
    
}

.updateProfileText{
    font-weight: 600;
}

.updateProfileProfileImage{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

}

.ProfileName{
    font-weight: 600;
}