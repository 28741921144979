.howTo1{
    height: auto;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
}
.howTo2{
    text-align:center;
    justify-content:center;
    font-size: 25px;
    font-weight: 800;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
}
.howTo3{
    text-align:center;
    justify-content:center;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
}
.img-row{
    max-width:100%;
    max-height:100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.heading-row{
    padding-top: 20px;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
    font-size: 16px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 1000;
    
}
.desc-row{
    padding-top: 15px;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
    font-size: 12px;
    text-align: center;
    justify-content: center;
     align-items: center;
    /*justify-content: center; */
}