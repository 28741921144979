.profileLinker{
    display:flex;
    gap:5px;
}

.ProfileSecond{
    font-weight: 700;
    padding-top: 18px;
    box-sizing: border-box;
}

.updateProfileProfileImage{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    background: #F4F5F9;

}

.wholecontainerflex{
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: auto auto;
    width: 100%;
}

.Namestyles{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
}
.redstart{
    color: red;
}

.aadhaarStyle{
    display: flex;
    gap: 30px;
    width: 20%;
}

.footerUpdateProfile{
    display: flex;
    flex-direction: column;
    gap: 20px;
}



svg-icon {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #72B01D;
    border-radius: 50%;
    /* padding: 0px 5px 0px 5px; */
    position: absolute;
    top: 65%;
    left: 80%;
  }

.updateProfileProfileImage1{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    background: #F4F5F9;
    
}

/* .profile-pi1 {
    border-radius: 50%;
    border: 3px solid #72B01D;
  }  */

  .profile{
    display: inline;
    position:relative;
  }

  .ProfileLabelStyles{
    font-family: Roboto;
    font-weight: 400;
  }

  .ProfileNameupdate{
    font-family: Roboto;
    font-weight: 700;
  }

  .ProfileUpdateButtonStyle{
    font-family: Roboto;
    font-weight: 700;
  }

  .ProfileUpdateFontFamily{
    font-family: Roboto;
  }