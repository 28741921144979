.whoTo1{
    height: auto;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
}
.whoTo2{
    text-align:center;
    justify-content:center;
    font-size: 25px;
    font-weight: 800;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
}
.whoTo3{
    text-align:center;
    justify-content:center;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
}
.img-col{
    
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.heading-col{
    padding-top: 18px;
    font-family: 'Varela Round', sans-serif;
    color: #324658;
    font-size: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 1000;
}
