.product{
    display: flex;
    gap: 5px;
    font-family: Roboto;
    font-weight: 400;
}
.product-details{
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.red-start{
    color: red;
}
.header-font{
    font-family: 'Roboto';
    font-weight: 400;
}
.text-style{
    font-family: Roboto;
    font-weight: 500;
}
.Add-product-btn{
    font-family: 'Roboto';
    font-weight: 700;
}
.pdt-price{
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 40px;
}
.pdt-category{
   display: grid;
   grid-template-columns: repeat(2, 3fr);
   column-gap: 40px;
}
.img-upload{
    height: 15%;
    width: 15%;
    border-radius: 5px;
}