/* .editform_container {
	display: block;
	width: 885px;	
	margin: 0 -75px;
	margin-top: -65px;
} */
.add_container{
	width:  1000px;
	/* margin: 0 -75px;
	margin-top:-65px; */
	background: #fff;
	box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
		0 6px 20px 0 rgba(165, 200, 213, 0.41);
	padding-left:50px;
	padding-right:70px;
	padding-bottom: 30px;
	padding-top: 30px;

}

.editform_div {
	padding: 40px;
}
#editable-form {
	width: 100%;
	font-family: arial, helvetica, sans-serif;
	box-sizing: border-box;
}
#editable-form .form-row {
	padding: 40px;
	font-size: 14px;
	/* width: 130px; */
	text-align: right;
	color: #33475b;
	display: block;
	float: none;
	width: auto;
	font-weight: 500;
	text-align: left;
	line-height: 20px;
	padding-top: 0;
	margin-bottom: 4px;
}
#editable-form .form-Col {
	align-self: flex-start;
	flex: 1 1 100%;
	padding: 0 10px;
}

#cancel-button,
#submit-button {
	width: 103px;
	float: right;
	margin-left: 15px;
}
.labl{
	font-weight: 200;
}
.css-2m9kme-MuiFormControl-root{
	width: 100% !important;
	margin: 8px 0 !important;
}

 @media(max-width:480px){
	.MuiSnackbar-root {
		left: 8px;
		right: 8px;
		display: flex;
		z-index: 1400;
		position: fixed;
		align-items: center;
		justify-content: center;
	}
}

@media(max-width:320px){
	.MuiSnackbar-root {
		left: 8px;
		right: 8px;
		display: flex;
		z-index: 1400;
		position: fixed;
		align-items: center;
		justify-content: center;
	}
}

@media(max-width:768px){
	.MuiSnackbar-root {
		left: 8px;
		right: 8px;
		display: flex;
		z-index: 1400;
		position: fixed;
		align-items: center;
		justify-content: center;
		}
	}

@media(min-width:768px){
	.MuiAlert-filledSuccess {
		color: #fff !important;
		font-weight: 500 !important;
		background-color: #4caf50 !important;
		margin-left: 52% !important;
		position: relative !important;
		left: 220px !important;
	}
}

@media (min-width:1024px){
	.MuiAlert-filledSuccess {
		color: #fff !important;
		font-weight: 500 !important;
		background-color: #4caf50 !important;
		margin-left: 61% !important;
        position: relative !important;
        left: 188px !important;
	}
}
@media (min-width:1280px){
	.MuiAlert-filledSuccess {
		color: #fff !important;
		font-weight: 500 !important;
		background-color: #4caf50 !important;
		margin-left: 2% !important;
        position: relative !important;
        left: 2px !important;
	}
}

