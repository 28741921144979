.ReadyColumnFlex{
    display: flex;
    flex-direction: column;
    gap: 8px;
 
}

.inProgressReady{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 6px;
}
.inProgressReady1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 3px;
    
    
}
.dateFontStyle1{
    font-family: Roboto;
    font-weight: 400;
}


.orderIdFontStyle{
    font-family: Roboto;
    font-weight: 600;
}
.dateFontStyle{
    font-family: Roboto;
    font-weight: 400;
}
.orderHistoryStyle{
    color: "#85BE49";
     font-weight: "600";
      text-decoration: "underline";
       cursor: "pointer";
}
.inProgressReadyFontStyle1{
    font-family: Roboto;
    font-weight: 400;
}

.containerscrollflex{
    overflow:scroll; 
/* height:500px;   */
    height: 62vh;
width:100%;
/* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;  */
 -ms-overflow-style: none; 
  scrollbar-width: none;  
  position: relative;
}

 containerscrollflex::-webkit-scrollbar {
    display: none;  
  } 

  .scrollable-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-left: 0px;
    /* flex-grow: 1; */
    height: 100%;
    width: 100%;
    position: absolute;
}
/* 
@media screen and (max-width: 1500px) and (min-width: 1300px) {
.containerscrollflex{
    height: 380px;
}
}
@media screen and (max-width: 1300px) and (min-width: 900px) {
.containerscrollflex{
    height: 370px;
}
}
@media screen and (max-width: 900px) and (min-width: 600px) {
.containerscrollflex{
    height: 330px;
}
}
@media screen and (max-width: 600px) and (min-width: 0px) {
.containerscrollflex{
    height: 320px;
}
}
 */
