
.custom-dialog{
    text-align: center;
    }
    .custom-dialog-btn-div{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    
    
    .SHOP-image {
        height: 28px;
        position:relative;
        right:30px;
    }
    
    /* input:focus { 
        outline: none !important;
        border-radius: 13px;
        border:1px solid rgba(196, 196, 196, 0.2);
        background: rgba(196, 196, 196, 0.2);
    } */
    textarea:focus { 
        outline: none !important;
        border:1px solid rgba(196, 196, 196, 0.2);
        background: rgba(196, 196, 196, 0.2);
    
    }
    .MuiButtonBase-root.MuiListItem-root.MuiListItem-padding.MuiListItem-button.jss2.jss3.css-bgzksb{
        height:auto !important;
    }   
    
    /* .css-h4y409-MuiList-root{
        background:#293332;
    } */