.custom-select_1 {
    border: 1px solid rgba(133, 190, 73, 1)
  }
  .custom-select_1:hover,
  .custom-select_1:focus {
    border-color: rgba(133, 190, 73, 1);
    color: rgba(133, 190, 73, 1);
  }
  
  .custom-select_1 .MuiSelect-icon {
    color: rgba(133, 190, 73, 1); 
  }