.about-container{
	margin-top:85px;
    background-color: rgb(247, 247, 247);
    border-radius:15px;
    padding-top:20px;
	
}
.about-container::before {    
	content: "";
	background-image:-webkit-linear-gradient(90deg, rgb(72,197,58) 0%, rgb(231,255,228) 100%);
	background-size: cover;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.2;
	border-radius: 20px;
}
.register-img{
	/* max-width:100%;
    max-height:100%; */
	position: absolute;
	z-index: 7;
    border-radius: 10px;
	right:6%;
	bottom:8%;
}
.aboutus{
	font-family: 'Varela Round', sans-serif;
	font-size: 14px;
	color: #324658;
    text-align: justify;
	padding: 30px;
	position: relative;
	z-index: 2;
}
@media only screen and (max-width: 768px) {
	.aboutus{
		font-family: 'Varela Round', sans-serif;
		font-size: 14px;
		color: #324658;
		text-align: justify;
		padding: 30px;
		/* position: relative;
		z-index: 2; */
	}
}
.Register-button{
	top:55%;
	left:47%;
	z-index: 7;
	position: absolute;
	justify-content: center;
	color: rgb(255, 255, 255);
	background-image: -webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%);
	/* box-shadow: 0px 14px 24px 0px rgba(93, 183, 96, 0.38); */
	min-width: auto;
	width: 203px;
	height: 50px;
	font-size: 16px;
	font-weight: 600;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-image: initial;
	overflow: hidden;
	outline: none;
	min-height: 14px;
	font-family: 'Varela Round', sans-serif;
}
@media only screen and (max-width: 768px) {
	.Register-button{
		position: relative;
		padding: 0;
		margin: 0;
		top:0;
		right: 0;
		left: 0;
		justify-content: center;
		color: rgb(255, 255, 255);
		background-image: -webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%);
		/* box-shadow: 0px 14px 24px 0px rgba(93, 183, 96, 0.38); */
		min-width: auto;
		width: 100%;
		height: 50px;
		font-size: 16px;
		font-weight: 600;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-width: 1px;
		border-style: solid;
		border-color: transparent;
		border-image: initial;
		overflow: hidden;
		outline: none;
		min-height: 14px;
		font-family: 'Varela Round', sans-serif;
	}
}

/* Landing.css */
.container_1 {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .image-container {
	display: none; /* Hide image on mobile screens */
  }
  
  .card-container1 {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
  }
  
  @media (min-width: 769px) {
	.image-container {
	  display: block; /* Show image on larger screens */
	}
	.container_1 {
	  flex-direction: row;
	}
	.card-container1 {
	  width: 50%;
	}
  }
  