.firstContainerStore{
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.StoreNameStyle{
    display: flex;
    flex-direction: column;
    gap: 10px;
}



.Namestyles{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
}


.aadhaarStyle{
    display: flex;
    gap: 30px;
    width: 20%;
}

.footerUpdateProfile{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.svg-icon {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #72B01D;
    border-radius: 50%;
    /* padding: 0px 5px 0px 5px; */
    position: absolute;
    top: 65%;
    
    left: 80%;
  }

.updateProfileProfileImage1{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

  .profile{
    display: inline;
    position:relative;
  }

  .ProfileSecond{
    font-family: Roboto;
    font-weight: 600;
  }

  .storeUpdateFontFamily{
    font-family: Roboto;
  }

  .ProfileName{
    font-size: Roboto;
    font-weight: 700;
  }

  .StoreLabelStyles{
    font-family: Roboto;
    font-weight: 400;
  }

  .StoreUpdateButtonStyle{
    font-family: Roboto;
    font-weight: 700;
  }