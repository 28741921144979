.wholecontainerflex{
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: auto auto;
    width: 100%;
    padding-top: 20px;
}
.Coupon-type{
    padding-top: 40px;
    font-family: Roboto;
    font-weight: 600;
    box-sizing: border-box;
}
.coupon-main{
    font-family: Roboto;
    font-weight: 400;
    color: #646464;
}
.Namestyles{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
}
.red-start{
    color: red;
}
.Name{
    font-family: Roboto;
    font-weight: 400;
}
.Coupon{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 20px;
}
.create-coupon{
    font-family: 'Roboto';
    font-weight: 700;
}

.css-4jnixx-MuiStack-root{
    padding:0px;
}

.css-1fpf3fe-MuiInputBase-root-MuiOutlinedInput-root {
    background: white;

}

.css-a643pv-MuiStack-root{
    padding-top: 0px;
}


.main{
    font-family: Roboto;
    font-weight: 400;
    color: #646464;
    cursor: pointer;
}