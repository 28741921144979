.form_container {
	display: block;
	background-color: "white";
	border: "1px solid rgba(0, 0, 0, 0.16)";
	border-radius: "10px";
}

.form_div {
	padding: 20px;
}
#signup-form {
	width: 100%;
	font-family: arial, helvetica, sans-serif;
	box-sizing: border-box;
}
.form-row {
	padding: 40px;
	font-size: 14px;
	width: 130px;
	text-align: right;
	color: #33475b;
	display: block;
	float: none;
	width: auto;
	font-weight: 500;
	text-align: left;
	line-height: 20px;
	padding-top: 0;
	margin-bottom: 4px;
}
.form-Col {
	align-self: flex-start;
	flex: 1 1 100%;
	padding: 0 10px;
}
/* ul {
	list-style-type: none;
 } */
/* 
ul li {
	margin: 66px;
	border-bottom: solid 1px grey; 
	margin: 66px;
 border-bottom: solid 1px grey; 
	font-size: large;
	color: rgba(0, 0, 0, 0.54);
	padding: 4px;
	font-size: 1rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.025em;
} */
#cancel-button,
#submit-button {
	width: 103px;
	/* float: right; */
	/* margin-left: 15px; */
}
