.OutColumnFlex{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inProgressOut{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 27px;
}
.inProgressOut1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* margin-top: 5px; */
    /* margin-right: 50px; */
}
.dateFontStyle1{
    font-family: Roboto;
    font-weight: 400;
    
}
.dateFontStyle2{
    font-family: Roboto;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.orderIdFontStyle{
    font-family: Roboto;
    font-weight: 600;
}
.dateFontStyle{
    font-family: Roboto;
    font-weight: 400;
}