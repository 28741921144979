.Couponstype{
   
    font-family: Roboto;
    font-weight: 600;
    margin-top: 20px;
    line-height: 20px;
   
}
.Coupon-sub{
    display: flex;
    gap: 5px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}
.main{
    font-family: Roboto;
    font-weight: 400;
    color: #646464;
}
.Percentage{
    font-family: Roboto;
   font-weight: 500;
   
   line-height: 13px;
}
.Coupon-percentage{
        font-family: Roboto;
        font-weight: 400;
        
        color: #8B8989;
}