.body{
    background-color: #F4F5F9;
}
.add-btn{
    display: flex;
    gap: 25px ;
}
.add-Button{
    font-family: 'Roboto';
    font-weight: 500;
}
.addnew-btn{
    font-family: 'Roboto';
    font-weight: 500;
}
.button-content{
    padding-top: "20px";
    padding-bottom:"20px"
}
.search-product{
    display: flex;
    margin-top: 20px;
    height: 50px;
    gap: 60px;
}
.filter{
    display: flex;
   margin-top: 10px;
   /* cursor: pointer; */
}
.filter-text{
    /* margin-top: 5px; */
    margin-left: 15px;
    font-family: Roboto;
    font-weight: 400;
    color: #8B8989;
}
.add-pdt-btn{
    float: right;
    padding: 20px;
    box-sizing: border-box;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 8.75px;
}
.add-icn{
    margin-right: 10px;
}
.card-pdt{
    margin-top: 30px;
}

.card-content{
    box-sizing: border-box;
    margin-top: 8px;
    padding: 35px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;
}
.addpdt-content{
    display: flex;
    margin-left: 23px;
}
.category-content{
    display: flex;
}
.edit{
    display: flex;
    gap: 25px;
    margin-left: auto;
}
.edit-icon{
    margin-top: 17px;
    margin-right: 25px;
}
.arrow{
    margin-top: 17px;
   margin-right: 5px;
}
.card-col{
    background-color: #85BE49;
}
.img-cnt{
    background-color: #F2F2F2;
    margin-bottom: 10px;
    border-radius: 15px;
}
.cls-rs{
    padding: 35px;
    box-sizing: border-box;
    align-items: right;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

}
.head-font{
    color: #170F49;
    text-align: "center";
    font-family: "DM sans";
    font-weight: "700";
}
.Catalog-Button{
    font-family: 'Roboto';
    font-weight: 400;
    color: black;
}
.para-font{
    font-family: 'DM sans';
    font-weight: 400;
}
.off-100{
    padding: 3px;
    color: white;
    font-family: 'Nunito';
    font-weight:500;
}

.container1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap:20px;
  }
  
  .image{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap:20px;
  }
  
  .sec-cnt{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price-pdt{
    /* color: black; */
    font-family: 'Roboto';
    font-weight: 700;
}
.product-button{
    width: 100%;
}
.para-font-1{
    font-family: Roboto;
    text-align: center;
}
.delete{
    font-family: Roboto;
    font-weight: 500;
}
.added-image {
    filter: grayscale(100%);
}