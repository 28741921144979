.MainOutForDelivery{
    display: flex;
    gap: 3px;
    font-family: Roboto;
    /* font-weight: 400; */
    font-size: 10px;
    color: #646464;
}
.containerOut{
    width: 92%;
  height: 300px;
  border-radius: 25px;
  background-color: white;
  margin-top: 50px;
  
}
.MainOutDelivery{
  display: flex;
  gap: 0px;
  font-family: Roboto;
  font-weight: 400;
}
.MainOut{
  font-family: "Roboto";
  font-weight: 400;
  color: #646464;
}
.MainOutForDelivery{
  display: flex;
  /* gap: 3px; */
  font-family: "Roboto";
  font-weight: 400;
  
  color: #85BE49;
} 
.flex-container{
    display: flex;
    justify-content: space-between;
}
.address{
  margin-left: 8px;
   font-weight: 400;
   font-family: "Roboto";
}
.textStyleOut {
  font-weight: 600;
  font-family: "Roboto";
  text-align: center;
  /* Remove display, justify-content, and align-items properties */
}

.outfordeliveryBox {
  width: 100%;
  height: 60px;
  background-color: #85BE4940;
  border-radius: 15px;
  margin-top: 20px;
  display: flex; /* Add flex display */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-family: "Roboto";
}
.addressOut{
  margin-left: 8px;
   font-weight: 400;
   font-family: "Roboto";
}
.numberStyleOut{
  margin-right: 20px;
  font-weight: 700;
  font-family: "Roboto";
  margin-left: 10px;
}
.nameStyleOut{
  font-family: "Nunito";
  font-weight: 700;
}
.OutForDeliveryGrid{
    display: grid;
    column-gap: 78px;
    margin-top: 30px;
    grid-template-columns: 98px 490px 900px;
    width: 90%;
    margin-left: 60px;
}
.checkbox-container {
    display: flex;
    justify-content: space-between;
}
.containers {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 60vh; */
}

.content {
  text-align: center;
}

.scanner-image {
  width: 170px;
  height: 170px;
 
}

.text {
  margin-top: 20px; /* Adjust as needed */
  font-family: "Roboto";
  font-weight: 400;
  color: black;
}
.containerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  margin: auto;
  width:34%;
  height:107px;
  
  border-radius: 24px;
}

.css-1ms7hib-MuiTimelineItem-root::before{
  padding:0px !important;
}

.connecting-line {
  border-left: 3px solid #85BE49;
  height: 100%;
  
  position: absolute;
  left: 4px;
  /*margin-left: -3px;*/
  top: 5px;
 
}
.productDetailsOut{
  font-weight: 500;
  font-family: "Roboto";
}
.productDetailOut{
     font-weight: 500;
     font-family: "Roboto";
     margin-top: 11px;
}  
.custom{
  padding-left: 0px;
}