.product-section {
	margin-top: 5rem;
}
.delivery-section {
	margin-top: 5rem;
}
#search-details {
	display: block;
	/* padding-top: 5px; */
	margin-block-end: 1em;
}
.delivery-section ul {
	list-style-type: none;
}
.delivery-section ul li {
	display: inline-block;
	border: none;
	margin: 66px;
	font-size: 1rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.025em;
	margin: 25px 95px 0;
	width: 140px;
}
.delivery-section ul .delivery-data {
	display: inline-block;
	margin: 15px 115px 0;
	color: black;
}
.container{
	width:  1000px;
	margin: 0 -75px;
	margin-top:-65px;
	background: #fff;
	box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
		0 6px 20px 0 rgba(165, 200, 213, 0.41);
}

.product-section ul {
	list-style-type: none;
	margin-top: 2rem;
}

.product-section ul li {
	display: inline-block;
	margin: 5px;
	color: black;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	letter-spacing: 0.025em;
	border: none;
}
.ActivateButton{
	justify-content: center;
	color: rgb(255, 255, 255);
	background-color:rgb(137, 199, 74,1);
	width:auto;
	float:right;
	height: 37px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	border: none;
	border-image: initial;
	overflow: hidden;
	outline: none;
	min-height: 14px;
	font-family: Varela Round, sans-serif;
}
.CloseButton{
	justify-content: center;
	color:  rgb(137, 199, 74,1);
	background-color:rgb(255, 255, 255);
	width:auto;
	float:right;
	height: 37px;
	border:1px solid rgb(137, 199, 74,1);
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	border: none;
	border-image: initial;
	overflow: hidden;
	outline: none;
	min-height: 14px;
	font-family: Varela Round, sans-serif;
}