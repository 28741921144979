.top-right {
    position: absolute;
    top: 25px;
    right: 36px;
}
.top-left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: absolute;
    top: 25px;
    left: 36px;
}
.top-left1 {
    /* display: flex;
    flex-direction: row;
    gap: 10px; */
    position: absolute;
    top: 155px;
    left: 36px;
}

  