.Block {
    width: 100%;
    height: 1px;
    /* dashed border */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
 }


 .GetAdorment{
    font-family: Nunito;
    font-weight: 600;
    color:'#8B8989';
 }

 .ProductListPopUpStyle{
   margin:20px 0px;
   display: flex;
   justify-content: space-between;
 }

 

 .ProductListFontStyle{
   font-family:DM Sans;
   font-weight: 700;
 }