.font-land{
  padding-top: 20px;
    font-family: 'Roboto';
    font-weight: 700;
    color: #659B1B;
}
.font-land1{
    font-family: 'Roboto';
    font-weight: 700;
    color: black;
}
.font_para{
  font-family: 'Roboto';
}
  
/* Landing.css */
.container_1 {
  display: flex;
  justify-content: space-between;
  background-image: url('../../utils/Icon/AboutUs.svg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  height: 100%; 
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.centered-image {
  max-width: 100%;
  height: auto;
}

/* 
  .container_1 {
    position: relative;
	padding-left: 80px;
	padding-right:80px;
	width: 100vw;
	height: 100%;
	overflow: hidden;
    display: flex;
    
  } */

  .image-container {
    flex: 1;
    overflow: hidden;
   
  }
  
  .image-container img {
    padding-top: 40%;
    width: 100%;
    height: auto;
  }
  .headerstylelearn{
    padding-left: 30px;
    text-align: left;
    font-family: Roboto;
    color: #13801C;
    font-weight: 300;
}
  .headerstylelearn1{
    font-family: Roboto;
    color: #13801C;
    font-weight: 700;
}
  .card-container1 {
    /* padding-top: 100px; */
    padding-top: 20px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    padding-right: 50px;
  }
  .content-cardabout{
    text-align: justify;
    padding: 30px;
    padding-top: 15px;
    padding-bottom: 60px;
    color: #5A7184;
}
  .custom-button {
    background: linear-gradient(to right, #13801C 100%, #FFFFFF 0%);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    width: auto;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background: red;
  }
  
  