/* .tableRow:hover {
	background-color:#eee;
    height:50px;
    box-shadow:0px 14px 24px 0px rgba(93, 183, 96, 0.38);
} */
.OutColumnFlex{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inProgressOut{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.inProgressOut1{
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
}