.footer-container {
    background-color: green;
    color: white;
    text-align: center;
  }
  
  .input-section {
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-top: 70px;
  }
  
  .input-fields {
    display: flex;
    width: 100%;
    gap: 30px;
  }

  
  .footer-line {
    border-top: 1px solid white;
    margin: 20px 0;
  }
  
.content-font{
  font-family: Roboto;
  text-align: left;
}
  .Menu{
    text-align: left;
  }

  
  .footer-copyright {
    padding: 20px;
    margin-top: 20px;
  }

  .custom-placeholder ::placeholder {
    font-size: 13px; 
  }
  