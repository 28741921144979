.BxgyProductName{
    font-family: Roboto;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
   
}


.BxgyProductPrice{
    font-family: Roboto;
    font-weight: 700;
    display: flex;
    
    align-items: center;
    height: 100%;

}

