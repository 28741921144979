.Main-Business{
    display: flex;
    gap: 3px;
    font-family: Roboto;
    font-weight: 400; 
    color: #646464;
}
.textsearch{
    padding-top: 20px;
}
.Business-pdt{
    padding-top: 20px;
    font-family: Roboto;
    font-weight: 600;
    box-sizing: border-box;
}
.select{
    padding-top: 40px;
    font-family: Roboto;
    font-weight: 500;
    color: #85BE49;
    text-decoration: underline;
}
.select-all{
    padding-top: 40px;
    font-family: Roboto;
    font-weight: 600; 
    box-sizing: border-box;

}
.price-product{
    /* color: black; */
    font-family: 'Roboto';
    font-weight: 700;
}
.added-image {
    filter: grayscale(100%);
}
.select-product{
    font-family: Roboto;
   font-weight: 500;
}