.ActiveOrderColumnFlex{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inProgressActive{ 
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    height: 100%;
    margin-top: 18px;
}
.inProgressActive1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    
}
.orderHistoryStyle{
    color:"#85BE49";
    font-weight:"600";
    text-decoration:"underline";
}
.orderStyle{
    display:"flex";
    justify-content:"flex-end";
    margin-top:"30px";
}
.orderIdFontStyleActive{
    font-family: "Roboto";
    font-weight: 600;
}
.dateFontStyleActive{
    font-family: "Roboto";
    font-weight: 400;
}
.dateFontStyle1{
    font-family: "Roboto";
    font-weight: 400;
}
.fixedButton {
    display: flex;
    height: 100px;
    background: rgb(244, 245, 249);
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px -1px 0px inset; */
    align-items: center;
    justify-content: space-between;
    width: calc(-230px + 100vw);
    
    padding: 20px 120px 20px 0px;;
    box-sizing: border-box;
    /* border-radius: 5px; */
    position: fixed;
    top: 50px;
    z-index: 2;
}
.containerscrollflexActive{
    overflow:scroll; 
/* height:500px;   */
    height: 62vh;
width:100%;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; 
 -ms-overflow-style: none; 
  scrollbar-width: none;  
  position: relative;
}

.containerscrollflex::-webkit-scrollbar {
    display: none;  
} 

.scrollable-contentActive {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-left: 0px;
    /* flex-grow: 1; */
    height: 100%;
    width: 100%;
    position: absolute;
}