.image-container {
    flex: 1;
    overflow: hidden;
    transition: transform 0.5s ease;
  }
  .card-container1 {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    padding-right: 50px;
    transition: transform 0.5s ease;
  }

.card {
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-in-out;
  padding: 20px;
    border-radius: 10px;
    height: 100%;
}

.card:hover {
  border-top: 8px solid #8B8989;
  border-bottom: 8px solid #8B8989;
}

  .header{
    text-align: center;
    color: #13801C;
    font-weight: 400;
  }
  .header-1{
    text-align: center;
    color: #13801C;
    font-weight: 600;
    font-family: Roboto;
  }
  .para{
    text-align:center;
    font-family: Roboto;
  }
  .custom-button1 {
    background: linear-gradient(to right, #13801C 100%, #FFFFFF 0%);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    /* width: auto; */
    padding: 10px 20px;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .custom-button1:hover {
    background: red;
  }
  .card:hover{
    border-top: "8px solid #13801C";
    border-bottom: "8px solid #13801C";
  }
  