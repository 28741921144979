.main{
    font-family: Roboto;
    font-weight: 400;
    color: #646464;
}
.Business-sub{
    display: flex;
    gap: 5px;
    font-family: Roboto;
    font-weight: 400;
}
.Businesstype{
    padding-top: 20px;
    font-family: Roboto;
    font-weight: 600;
    box-sizing: border-box;
}
.product{
    font-family: Roboto;
    font-weight: 400;
    color: #8B8989;
}
.Bevarages{
    font-family: Roboto;
   font-weight: 500;
}