.Contact-info{
    /* text-align: center; */

    font-family: 'Roboto';
    font-weight: 500;
    color: white;
}
.contact-no{
    text-align: center;
    font-family: 'Roboto';
    color: white;
}
.background{
    padding: 20px;
    width: 100vw;
    height: 100%;
    background: linear-gradient(to bottom, #ffff, #e0ffe0);
}
.Contactus{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 700;
    color:  #13801C;
}
.Contactustext{
    text-align: center;
    font-family: 'Roboto';
    color: 500;
    color:  #717171;
}
.Contactformtext{
    font-family: 'Roboto';
    color: 500;
    color:  #717171;
}
.select-sub{
    font-family: 'Roboto';
    font-weight: 600;
    color: black;
}
.button{
    font-family: "Roboto";
    color: white;
    font-weight: 600;
}