.btn-button{
    justify-content: center;
	color: rgb(255, 255, 255);
	background-color: rgb(137, 199, 74, 1);
	box-shadow: 0px 14px 24px 0px rgba(93, 183, 96, 0.38);
	min-width: auto;
	/* width: 50%; */
	height: 37px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-image: initial;
	overflow: hidden;
	outline: none;
	min-height: 14px;
	font-family: 'Varela Round', sans-serif;
}


