.Business{
    font-family: Roboto;
    font-weight: 600;
}
.Business-card{
    padding-top: 30px;
    padding-right: 20px;
    box-sizing: border-box;
}
.Restaurant{
   font-family: Roboto;
   font-weight: 500;
   line-height: 13px;

}
.Business-card-1{
    /* padding-top: 20px;
    padding-right: 20px; */
    /* padding: 20px; */
    padding-top: 30px;
    /* padding-right: 20px; */
    box-sizing: border-box;
}